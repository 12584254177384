import React from "react"

import Footer from "../components/footer"
import LandingForm from "../components/landing-form"
import LandingChat from "../components/landingChat"
import { Link } from "gatsby"
import SEO from "../components/seo"

import "../components/style.scss"
import "../styles/styles.css"
import "../styles/new-landing.css"
import phoneImg from "../images/blackPhone.png"
import placePinImg from "../images/place_pin.png"

const NewLanding = () => {
  return (
    <div>
      <SEO title="PhoneFix Pro - Schedule a Free iPhone Diagnostics" />
      <section className="hero newLandingHero  is-fullheight-with-navbar">
        <nav className="navbar landingNavbar">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item" to={"/"}>
                <h3 style={{ fontSize: 1.3 + `rem`, color: `#FFF` }}>
                  PhoneFix <span style={{ fontWeight: 700 }}>PRO</span>
                </h3>
              </Link>
            </div>
          </div>
        </nav>
        <div className="hero-body">
          <div className="container center">
            <div className="columns">
              <div className="column">
                <article className="media landingPageHeader">
                  <div className="media-content">
                    <div className="" style={{ paddingLeft: 30 + "px" }}>
                      <h1 className="has-text-white headerTextLanding">
                        Schedule a Repair
                      </h1>
                      <p className="subtitle has-text-white">
                        Fast Friendly Local
                      </p>
                      <div className="landingPageLocation">
                        <img src={placePinImg} alt="PhoneFix PRO Location" />
                        <span className="has-text-white">
                          Inside Lloyd Center Mall
                        </span>
                      </div>
                    </div>
                  </div>
                </article>
                <div className="landingContentWrapper">
                  <LandingForm theme={"dark"} />
                  <div className="column landingPhoneImageWrapper landingImageHeader">
                    <img
                      className="ls-s-1 landingImage darkLandingImage"
                      src={phoneImg}
                      alt="Phone"
                    />
                    <LandingChat />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default NewLanding
